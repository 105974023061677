import roleService from "../services/role.service";

const roleStore = {
  state: {
    roles: [],
  },
  mutations: {
    setRoles: function(state, roles) {
      state.roles = roles;
    },
  },
  actions: {
    loadRoles: async function({ commit }) {
      try {
        const roles = await roleService.fetchRoles();
        commit("setRoles", roles);
        return roles;
      } catch (e) {
        console.log(e);
      }
    },
  },
};

export default roleStore;
