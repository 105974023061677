<template>
  <div class="flex h-full items-end pr-7 space-x-7">
    <div v-for="(item, i) in userMenuItems" :key="i">
      <NavItem
        :title="item.title"
        :icon="item.icon"
        :path="item.path"
        :disabled="item.disabled"
      />
    </div>
  </div>
</template>

<script>
import NavItem from "./NavItem.vue";
import { userMenuItems as menus } from "../../common/userMenuItems";
export default {
  components: { NavItem },
  name: "NavMenuItems",
  computed: {
    userMenuItems: function () {
      const userOrgRole = this.$store.getters.userOrgRole;
      const userOrg = userOrgRole.org;
      const userRole = userOrgRole.role;
      let menuItems = [];
      if (userRole === "Executive") {
        menuItems.push(menus.executive, menus.projects);
      } else if (["SuperAdmin", "SuperAdmin"].includes(userRole)) {
        menuItems.push(
          menus.execAdmin,
          menus.activities,
          menus.sampleOffice,
          menus.members,
          menus.medOffice
        );
      } else {
        if (userOrg && userOrg.type === "medical center") {
          menuItems.push(
            menus.dashboard({
              name: "MedicalDashboard",
              params: { orgid: userOrg.id },
            }),
            menus.samples({
              name: "MedicalSamples",
              params: { orgid: userOrg.id },
            })
          );
        }
        if (userOrg && userOrg.type === "bioresource center") {
          menuItems.push(
            menus.dashboard({
              name: "BioDashboard",
            }),
            menus.samples({
              name: "BioSamples",
            })
          );
        }
        if (userOrg && userOrg.type === "sequencing lab") {
          menuItems.push(
            menus.dashboard({
              name: "LabDashboard",
            }),
            menus.samples({
              name: "LabSamples",
            })
          );
        }
        if (userOrg && userOrg.type === "genome databank") {
          menuItems.push(
            menus.dashboard({
              name: "BankDashboard",
            }),
            menus.samples({
              name: "BankSamples",
            })
          );
        }
        if (userOrg && userRole === "Admin") {
          menuItems.push(menus.members);
        }
      }
      return menuItems;
    },
  },
};
</script>