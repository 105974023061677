import Vue from "vue";
import Vuex from "vuex";

import authStore from "./auth";
import roleStore from "./role";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: authStore,
    role: roleStore
  },
});
