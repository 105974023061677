import { isAuth } from "./isAuth";

const labRoutes = [
  {
    path: "/lab/dash",
    beforeEnter: isAuth,
    component: () => import("../views/sequencingLab/Dashboard.vue"),
    name: "LabDashboard",
  },
  {
    path: "/lab/samples",
    beforeEnter: isAuth,
    component: () => import("../views/sequencingLab/Samples.vue"),
    name: "LabSamples",
  },
  {
    path: "/lab",
    redirect: "/lab/dash",
  },
];

export default labRoutes;
