import store from "../store/index";

export function getUserHome() {
  let homePage = "/profile";
  if (store.getters.loggedIn) {
    let userOrgRole = store.getters.userOrgRole;
    let userOrg = userOrgRole.org;
    let userRole = userOrgRole.role;
    if (userRole === "Executive") {
      homePage = "/exec";
    } else if (userRole === "SuperAdmin") {
      homePage = "/exec";
    } else {
      if (userOrg && userOrg.type === "medical center") {
        homePage = `medical/${userOrg.id}/dash`;
      }
      if (userOrg && userOrg.type === "bioresource center") {
        homePage = "/bio/dash";
      }
      if (userOrg && userOrg.type === "sequencing lab") {
        homePage = "/lab/dash";
      }
      if (userOrg && userOrg.type === "genome databank") {
        homePage = "/bank/dash";
      }
    }
  }
  return homePage;
}
