import { isAuth } from "./isAuth";

const bioRoutes = [
  {
    path: "/bio/dash",
    beforeEnter: isAuth,
    component: () => import("../views/bioresourceCenter/Dashboard.vue"),
    name: "BioDashboard",
  },
  {
    path: "/bio/samples",
    beforeEnter: isAuth,
    component: () => import("../views/bioresourceCenter/Samples.vue"),
    name: "BioSamples",
  },
  {
    path: "/bio",
    redirect: "/bio/dash",
  },
];

export default bioRoutes;
