<template>
  <div class="h-full flex items-center">
    <NavMedCenter v-if="show('medical')" />
    <NavStationItem station="bio" v-if="show('bio')" />
    <NavStationItem station="lab" v-if="show('lab')" />
    <NavStationItem station="bank" v-if="show('bank')" />
  </div>
</template>

<script>
import NavMedCenter from "./NavMedCenter.vue";
import NavStationItem from "./NavStationItem.vue";
export default {
  components: { NavStationItem, NavMedCenter },
  name: "NavOrgItems",
  methods: {
    show: function(station) {
      if (!this.$route.name) return false;
      return (
        (this.$route.name.includes("Medical") && station === "medical") ||
        (this.$route.name.includes("Bio") && station === "bio") ||
        (this.$route.name.includes("Lab") && station === "lab") ||
        (this.$route.name.includes("Bank") && station === "bank")
      );
    },
  },
};
</script>