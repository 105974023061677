import Vue from "vue";
import VueRouter from "vue-router";
import $eventHub from "../components/eventHub";
import medicalRoutes from "./medical";
import bioRoutes from "./bio";
import labRoutes from "./lab";
import bankRoutes from "./bank";
import { isAuth } from "./isAuth";
import { isNotAuth } from "./isNotAuth";
import { isExecutiveOrSuperAdmin } from "./IsExecutiveOrSuperAdmin";
import { isAdminOrSuperAdmin } from "./IsAdminOrSuperAdmin";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    beforeEnter: isAuth,
  },
  {
    path: "/newpass",
    name: "authNewPass",
    component: () => import("../views/Authentication.vue"),
    beforeEnter: isNotAuth,
  },
  {
    path: "/forget",
    name: "authForget",
    component: () => import("../views/Authentication.vue"),
    beforeEnter: isNotAuth,
  },
  {
    path: "/verify",
    name: "authVerify",
    component: () => import("../views/Authentication.vue"),
    beforeEnter: isNotAuth,
  },
  // {
  //   path: "/signup",
  //   name: "authSignUp",
  //   component: () => import("../views/Authentication.vue"),
  //   beforeEnter: isNotAuth,
  // },
  {
    path: "/login",
    name: "authLogIn",
    component: () => import("../views/Authentication.vue"),
    beforeEnter: isNotAuth,
  },
  {
    path: "/exec",
    name: "Executive",
    component: () => import("../views/Executive.vue"),
    beforeEnter: isExecutiveOrSuperAdmin,
  },
  {
    path: "/exec/medcenweekrep",
    name: "ExecutiveMedCen",
    component: () => import("../views/ExecMedCen.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/sample/:sid",
    name: "SampleDetail",
    component: () => import("../views/SampleDetail.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/phenotype/:sid",
    name: "Phenotype",
    component: () => import("../views/Phenotype.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/samples",
    name: "SampleOffice",
    component: () => import("../views/backOffice/SampleOffice.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/activities",
    name: "Activities",
    component: () => import("../views/backOffice/Activities.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/members",
    name: "Members",
    component: () => import("../views/backOffice/Members.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/medicalcenters",
    name: "MedOffice",
    component: () => import("../views/backOffice/MedicalCenters.vue"),
    beforeEnter: isAuth,
  },
  {
    path: "/edit-target",
    name: "editTarget",
    component: () => import("../views/editTarget/OrgList.vue"),
    beforeEnter: isAdminOrSuperAdmin,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/privacy-geth",
    name: "PrivacyPolicyGeTH",
    component: () => import("../views/PrivacyNotice.vue"),
  },
  ...medicalRoutes,
  ...bioRoutes,
  ...labRoutes,
  ...bankRoutes,
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (typeof to.matched[0]?.components.default === "function") {
    $eventHub.$emit("asyncComponentLoading", to); // Start progress bar
  }
  next();
});

router.beforeResolve((to, from, next) => {
  $eventHub.$emit("asyncComponentLoaded"); // Stop progress bar
  next();
});

export default router;