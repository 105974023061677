import axios from "axios";
import addQuery from "../utils/addQuery";

const forgetPassword = (email) => {
  const url = "/auth/forget";
  return axios.post(url, { email }).then((res) => res.data);
};

const resetPassword = (id, token, newPassword) => {
  const url = addQuery(`/auth/forget/${id}`, { token });
  return axios.patch(url, { newPassword }).then((res) => res.data);
};

const resendMail = (data) => {
  return axios.post("/auth/resendMail", data);
};

const login = () => {
  return axios.post(`/auth/login`).then((res) => res.data);
};

const me = () => {
  return axios.get(`/auth/me`).then((res) => res.data);
};

const requestOTP = () => {
  return axios.get(`/auth/requestOTP`).then((res) => res.data);
};

const logout = () => {
  return axios.post(`/auth/logout`).then((res) => res.data);
};

const checkVerification = () => {
  return axios.get(`/auth/checkVerification`).then((res) => res.data);
};

export default {
  forgetPassword,
  resetPassword,
  resendMail,
  login,
  me,
  requestOTP,
  logout,
  checkVerification,
};