<template>
  <div class="h-screen flex flex-col">
    <load-bar />
    <div :style="{ height: '6.2rem' }" class="flex-none">
      <Navbar />
    </div>
    <div class="h-full" :class="layoutClass">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
    <div class="flex w-full justify-end px-2 text-gray-500 text-sm">
      <p>Version 1.1</p>
    </div>
  </div>
</template>

<script>
import LoadBar from "./components/LoadBar.vue";
import Navbar from "./components/navbar/NavbarMain.vue";
export default {
  name: "App",
  components: { LoadBar, Navbar },
  computed: {
    layoutClass: function () {
      if (this.$route.name) {
        if (this.$route.name.includes("Samples")) {
          return "w-full px-8 pt-8 pb-5";
        } else if (this.$route.name.includes("auth")) {
          return "";
        } else return "w-full px-0 sm:px-20 pt-5 sm:pt-8 pb-5";
      } else return "";
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200;400&display=swap");
* {
  font-family: "Ubuntu", sans-serif;
}
.th-font {
  font-family: "Kanit", sans-serif;
}
body {
  background-color: #e1ebf5;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
