import { isAuth } from "./isAuth";

const medicalRoutes = [
  {
    path: "/medical/:orgid/dash",
    beforeEnter: isAuth,
    component: () => import("../views/medicalCenter/Dashboard.vue"),
    name: "MedicalDashboard",
  },
  {
    path: "/medical/:orgid/samples",
    beforeEnter: isAuth,
    component: () => import("../views/medicalCenter/Samples.vue"),
    name: "MedicalSamples",
  },
  {
    path: "/medical/:orgid",
    redirect: "/medical/:orgid/dash",
  },
  {
    path: "/medical",
    redirect: "/medical/1/dash",
  },
];

export default medicalRoutes;
