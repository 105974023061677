import { isAuth } from "./isAuth";

const bankRoutes = [
  {
    path: "/bank/dash",
    beforeEnter: isAuth,
    component: () => import("../views/genomeDataBank/Dashboard.vue"),
    name: "BankDashboard",
  },
  {
    path: "/bank/samples",
    beforeEnter: isAuth,
    component: () => import("../views/genomeDataBank/Samples.vue"),
    name: "BankSamples",
  },
  {
    path: "/bank",
    redirect: "/bank/dash",
  },
];

export default bankRoutes;
