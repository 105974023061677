import axios from "axios";
import Vue from "vue";
import keycloak from "../keycloak";
import authService from "../services/auth.service";

const authStore = {
  state: {
    loading: false,
    token: null,
    user: null,
    org: null,
    otpLoggedIn: false,
    otpToken: null,
  },
  mutations: {
    auth_logOut(state) {
      state.token = null;
      state.user = null;
      state.org = null;
    },
    auth_setToken(state, token) {
      state.token = token;
    },
    auth_setUser(state, userData) {
      state.user = userData;
    },
    auth_setLoad(state, isLoad) {
      state.loading = isLoad;
    },
    auth_setOrg(state, org) {
      state.org = org;
    },
    auth_otpLogin(state, isLoggedIn) {
      state.otpLoggedIn = isLoggedIn;
    },
    request_otp(state, token) {
      state.otpToken = token;
    },
    clear_otp(state) {
      state.otpToken = null;
    },
  },
  actions: {
    async register({ commit }, regData) {
      try {
        commit("auth_setLoad", true);
        const res = await axios.post("/auth/register", regData);
        commit("auth_setLoad", false);
        return res;
      } catch (e) {
        commit("auth_setLoad", false);
        throw e.response.data;
      }
    },
    logIn({ commit }) {
      commit("auth_setLoad", true);
      keycloak.login();
    },
    async checkAuth({ commit }) {
      try {
        if (keycloak.authenticated) {
          const profile = await authService.me();
          commit("auth_setUser", profile);
          commit("auth_setToken", keycloak.idToken);
          commit("auth_setOrg", profile.organization);
          commit("auth_otpLogin", true);
          return profile;
        } else {
          return null;
        }
      } catch (e) {
        return null;
      }
    },
    async requestOTP({ commit }) {
      try {
        const res = await authService.requestOTP();
        commit("request_otp", res.token);
        return res;
      } catch (e) {
        commit("clear_otp");
      }
    },
    checkOTP({ commit }, otpData) {
      commit("auth_setLoad", true);
      return new Promise((resolve, reject) => {
        axios
          .post("/auth/validateOTP", otpData)
          .then((res) => {
            const user = res.data;
            const token = keycloak.idToken;
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
            commit("auth_setToken", token);
            commit("auth_setUser", user);
            commit("auth_otpLogin", true);
            commit("auth_setLoad", false);
            commit("clear_otp");
            resolve();
          })
          .catch((err) => {
            commit("auth_setLoad", false);
            commit("clear_otp");
            reject(err.response.data);
          });
      });
    },
    async logOut({ commit }) {
      await authService.logout();
      commit("auth_logOut");
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      keycloak.logout();

      delete axios.defaults.headers.common["Authorization"];
      Vue.$toast.error("You've been logged out", {
        position: "top-right",
        hideProgressBar: true,
        timeout: 2000,
      });
    },
  },
  getters: {
    ssoLoggedIn() {
      return keycloak.authenticated;
    },
    otpLoggedIn(state) {
      return state.otpLoggedIn;
    },
    loggedIn(state) {
      return keycloak.authenticated && state.otpLoggedIn;
    },
    authLoad(state) {
      return state.loading;
    },
    navProfile(state) {
      const user = state.user;
      const firstName = user ? user.firstname : "";
      const lastName = user ? user.lastname : "";
      const role = user && user.role ? user.role.name : null;
      const imageUrl = user ? user.imageUrl : "../assets/img/defpfp.png";
      return {
        name: `${firstName} ${lastName}`,
        role,
        imageUrl,
      };
    },
    profileData(state) {
      const user = state.user;
      const firstName = user ? user.firstname : null;
      const lastName = user ? user.lastname : null;
      const email = user ? user.email : null;
      const role = user && user.role ? user.role.name : null;
      const organization = user ? user.organization : null;
      const orgType = organization ? organization.type : null;
      const orgName = organization ? organization.name : null;
      return [
        { field: "Name", val: firstName },
        { field: "Surname", val: lastName },
        { field: "Email", val: email },
        {
          field: "Organization",
          val: `${orgType || "-"}  ${orgName || ""}`,
        },
        { field: "Role", val: `${role}` },
      ];
    },
    userData(state) {
      return state.user;
    },
    userOrgRole(state) {
      const user = state.user;
      const role = user && user.role ? user.role.name : null;
      const org = user ? user.organization : null;
      return { role, org };
    },
  },
};

export default authStore;
