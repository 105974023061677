const apiUrl = "https://satosheep.tech/sms-api";

const defaultOAuthOption = {
  url: "https://sso.genomicsthailand.com/auth",
  realm: "geth",
  clientId: "sms"
}

const config = {
  apiUrl: process.env.VUE_APP_API_URL || apiUrl,
  oauth: {
    url: process.env.VUE_APP_OAUTH_URL || defaultOAuthOption.url,
    realm: process.env.VUE_APP_OAUTH_REALM || defaultOAuthOption.realm,
    clientId: process.env.VUE_APP_OAUTH_CLIENT_ID || defaultOAuthOption.clientId,
    username: process.env.VUE_APP_OAUTH_USERNAME,
    password: process.env.VUE_APP_OAUTH_PASSWORD
  }
};

export default config;