<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="25"
    viewBox="0 0 29 25"
    class="stroke-current h-7"
  >
    <g id="user-plus" transform="translate(0.5 -1.5)">
      <path
        id="Path_290"
        data-name="Path 290"
        d="M19.333,22.333V19.889A4.889,4.889,0,0,0,14.444,15H5.889A4.889,4.889,0,0,0,1,19.889v2.444"
        transform="translate(0 2.666)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <circle
        id="Ellipse_89"
        data-name="Ellipse 89"
        cx="4.889"
        cy="4.889"
        r="4.889"
        transform="translate(5.278 3)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <line
        id="Line_388"
        data-name="Line 388"
        y2="6"
        transform="translate(24 10)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
      <line
        id="Line_389"
        data-name="Line 389"
        x1="6"
        transform="translate(21 13)"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="3"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "MembersIcon",
};
</script>
