<template>
  <nav class="w-full h-full shadow-md bg-white">
    <div class="flex h-full w-full justify-between">
      <div class="flex">
        <div
          @click="$router.push('/').catch(() => {})"
          class="h-full w-56 flex flex-col justify-center items-center cursor-pointer select-none text-primary hover:text-blue-400"
          :class="!isLogin ? '' : 'sm:border-r border-gray-300'"
        >
          <p class="font-medium text-2xl sm:text-3xl p-0 m-0">Specimen</p>
          <p class="text-gray-600 text-sm sm:hidden p-0 m-0">
            Management System
          </p>
          <p class="text-gray-600 hidden sm:block p-0 m-0">
            Management System
          </p>
        </div>
        <NavOrgItems />
      </div>
      <div class="flex h-full items-center" v-if="isLogin">
        <NavMenuItems class="hidden sm:flex" />
        <NavUserInfo />
      </div>
    </div>
  </nav>
</template>

<script>
import NavMenuItems from "./NavMenuItems.vue";
import NavOrgItems from "./NavOrgItems.vue";
import NavUserInfo from "./NavUserInfo.vue";
export default {
  components: { NavUserInfo, NavMenuItems, NavOrgItems },
  name: "NavbarMain",
  computed: {
    isLogin: function() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>