import store from "../store/index";
import { getUserHome } from "./getUserHome";

export async function isAdminOrSuperAdmin(to, from, next) {
  if (!store.getters.ssoLoggedIn) {
    next({ name: "authLogIn" });
  } else {
    const profile = store.state.auth.user
      ? store.state.auth.user
      : await store.dispatch("checkAuth");

    if (!profile) {
      next({ name: "authLogIn" });
    } else if (!["Admin", "SuperAdmin"].includes(profile.role.name)) {
      next(getUserHome());
    } else {
      next();
    }
  }
}
