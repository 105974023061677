<template>
  <div
    class="flex h-full flex-col items-center justify-end pt-3.5 select-none"
    :class="isDisabled ? 'cursor-not-allowed' : 'cursor-pointer'"
    @click="handleRoute"
    @mouseover="isHover = true"
    @mouseleave="isHover = false"
  >
    <div class="flex flex-col items-center">
      <div
        :class="[
          isActive ? 'text-primary' : 'text-gray-400',
          isHover && !isActive && !isDisabled && 'text-gray-500',
        ]"
      >
        <ExecIcon v-if="icon === 'Executive'" />
        <DashIcon v-if="icon === 'Dashboard'" />
        <SampleIcon v-if="icon === 'Samples'" />
        <ProjectIcon v-if="icon === 'Projects'" />
        <DashIcon v-if="icon === 'Activities'" />
        <MembersIcon v-if="icon === 'Members'" />
        <MedCenIcon v-if="icon === 'Medical'" class="h-7" />
      </div>
      <p
        class="text-lg"
        :class="[
          isActive ? 'font-medium text-primary' : 'text-gray-400',
          isHover && !isActive && !isDisabled && 'text-gray-500',
        ]"
      >
        {{ title }}
      </p>
    </div>
    <svg
      class="mt-2 bottom-0 relative fill-current text-white"
      :class="
        isActive
          ? 'text-primary'
          : isHover && !isActive && !isDisabled && 'text-gray-500'
      "
      xmlns="http://www.w3.org/2000/svg"
      width="15.999"
      height="8"
      viewBox="0 0 15.999 8"
    >
      <path
        id="Subtraction_1"
        data-name="Subtraction 1"
        d="M21,8H5A8,8,0,0,1,21,8Z"
        transform="translate(-5)"
      />
    </svg>
  </div>
</template>

<script>
import DashIcon from "../../assets/svg/DashIcon.vue";
import ExecIcon from "../../assets/svg/ExecIcon.vue";
import SampleIcon from "../../assets/svg/SampleIcon";
import ProjectIcon from "../../assets/svg/ProjectIcon";
import MembersIcon from "../../assets/svg/MembersIcon.vue";
import MedCenIcon from "../../assets/svg/MedCenIcon.vue";
export default {
  components: {
    ExecIcon,
    DashIcon,
    SampleIcon,
    ProjectIcon,
    MembersIcon,
    MedCenIcon,
  },
  name: "NavItem",
  props: ["title", "icon", "path", "disabled"],
  data: function () {
    return {
      isDisabled: this.disabled,
      isHover: false,
    };
  },
  computed: {
    isActive: function () {
      return !this.disabled && this.$route.name === this.path.name
        ? true
        : false;
    },
  },
  methods: {
    handleRoute: function () {
      this.$router.push(this.path).catch(() => {});
    },
  },
};
</script>