<template>
  <div class="h-full">
    <div
      class="pl-6 pr-5 h-full flex justify-center items-center cursor-pointer border-l border-gray-300 select-none hover:bg-smswhite"
      @click="isOpen = !isOpen"
      v-click-outside="hideMenu"
    >
      <img
        class="h-11 w-11 rounded-full"
        :src="$store.getters.navProfile.imageUrl"
        alt="profilePic"
      />
      <div class="flex flex-col text-sm ml-5 mr-2">
        <p class="text-gray-700 font-semibold">
          {{ $store.getters.navProfile.name }}
        </p>
        <p class="text-gray-600">{{ $store.getters.navProfile.role }}</p>
      </div>
      <svg
        class="h-8 text-primary transform transition-transform"
        :class="isOpen && 'rotate-180'"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </div>
    <template v-if="isOpen">
      <div
        :style="{ height: '3.2rem' }"
        class="flex justify-start items-center cursor-pointer border border-t-1 border-gray-300 px-7 relative bg-smswhite z-50 hover:bg-white hover:text-primary text-smstext"
        @click="goToProfile"
      >
        <svg
          class="h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
          ></path>
        </svg>
        <p class="ml-3.5 select-none text-sm font-light">Profile</p>
      </div>
      <div
        v-if="showEditTarget"
        :style="{ height: '3.2rem' }"
        class="flex justify-start items-center cursor-pointer border border-b-1 border-t-0 border-gray-300 px-7 relative bg-smswhite z-50 hover:bg-white hover:text-primary text-smstext"
        @click="goToEditTarget"
      >
        <svg
          class="h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
          ></path>
        </svg>
        <p class="ml-3.5 select-none text-sm font-light">Edit Target</p>
      </div>
      <div
        :style="{ height: '3.2rem' }"
        class="flex justify-start items-center cursor-pointer border border-t-0 border-gray-300 px-7 relative bg-smswhite z-50 hover:bg-white text-smstext hover:text-red-400"
        @click="logOut"
      >
        <svg
          class="h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
          ></path>
        </svg>
        <p class="ml-3.5 select-none text-sm font-light">Logout</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "NavUserInfo",
  data: function () {
    return {
      isOpen: false,
    };
  },
  computed: {
    showEditTarget() {
      return (
        this.$store.getters.userOrgRole.role === "SuperAdmin" ||
        this.$store.getters.userOrgRole.role === "Admin"
      );
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("logOut");
    },
    goToProfile() {
      this.isOpen = false;
      if (this.$route.name !== "Profile") {
        this.$router.push({ name: "Profile" });
      }
    },
    goToEditTarget() {
      this.isOpen = false;
      if (this.$route.name !== "editTarget") {
        this.$router.push({ name: "editTarget" });
      }
    },
    hideMenu() {
      this.isOpen = false;
    },
  },
};
</script>