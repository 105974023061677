import axios from "axios";
import addQuery from "../utils/addQuery";

const fetchOrgs = async (params) => {
  const url = addQuery("/organizations", params);
  return axios.get(url).then((res) => res.data);
};

const fetchMedicalCenters = async (params) => {
  const url = addQuery("/organizations/medicals", params);
  return axios.get(url).then((res) => res.data);
};

const fetchMedicalCentersBackOffice = async (params) => {
  const url = addQuery("/organizations/medicals/backoffice", params);
  return axios.get(url).then((res) => res.data);
};

const fetchRoles = async () => {
  const data = await axios.get("/roles");
  return data.data;
};

const addMedicalCenter = async (medCenVal) => {
  await axios.post("/organizations", medCenVal);
};

const deleteOrg = async (id) => {
  await axios.delete(`/organizations/${id}`);
};

const updateOrg = async (id, editVal) => {
  await axios.patch(`/organizations/${id}`, editVal);
};

const fetchMedCenName = async (id) => {
  const data = await axios.get(`/organizations/${id}`);
  return data.data.name;
};

export default {
  fetchOrgs,
  fetchRoles,
  fetchMedicalCenters,
  fetchMedicalCentersBackOffice,
  fetchMedCenName,
  addMedicalCenter,
  deleteOrg,
  updateOrg,
};
