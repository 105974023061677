import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import axios from "axios";
import config from "./config";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueApexCharts from "vue-apexcharts";
import keycloak, { initOptions } from "./keycloak";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;

axios.defaults.baseURL = config.apiUrl;

axios.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 403) {
      router.push("/");
    }
    throw err;
  }
);

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

Vue.use(Toast, { transition: "Vue-Toastification__fade" });

Vue.prototype.$keycloak = keycloak;

keycloak
  .init(initOptions)
  .then((auth) => {
    if (auth) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${keycloak.token}`;

      // TODO: remove this
      console.log(keycloak.token);

      // Token Refresh
      setInterval(() => {
        keycloak
          .updateToken(200)
          .then((refreshed) => {
            if (refreshed) {
              axios.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${keycloak.token}`;
              console.log(keycloak.token);
            }
          })
          .catch(() => {
            console.error("Failed to refresh token");
          });
      }, 6000);
    }
  })
  .catch((e) => {
    console.error("Error", e);
    console.error("Authenticated Failed");
  })
  .finally(() => {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  });
