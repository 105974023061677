import Keycloak from "keycloak-js";

export const initOptions = {
  url: "https://sso.genomicsthailand.com/auth",
  realm: "geth",
  clientId: "sms",
  onLoad: "check-sso",
  pkceMethod: "S256",
};

const keycloak = Keycloak(initOptions);

export default keycloak;