<template>
  <router-link :to="medicalLink">
    <div class="flex items-center pl-2 text-smstext select-none">
      <MedCenSecIcon class="mr-1.5 h-16" />
      <div class="flex text-xl font-medium w-60">
        <p class="text-xl font-medium th-font hover:text-primary">
          {{ medCenName }}
          <span
            class="text-primary font-bold bg-blue-100 rounded-full"
            :style="{ fontSize: '1.4rem' }"
            >①</span
          >
        </p>
      </div>
    </div>
  </router-link>
</template>

<script>
import MedCenSecIcon from "../../assets/svg/MedCenSecIcon.vue";
import orgService from "../../services/org.service";
export default {
  components: { MedCenSecIcon },
  name: "NavMedCenter",
  data: function() {
    return {
      medCenName: "Loading..",
    };
  },
  watch: {
    "$route.params.orgid": {
      handler: function() {
        this.fetchOrg();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    medicalLink: function() {
      return `/medical/${this.$route.params.orgid}/dash`;
    },
  },
  methods: {
    fetchOrg: async function() {
      const medCenNameData = await orgService.fetchMedCenName(
        this.$route.params.orgid
      );
      this.medCenName = medCenNameData;
    },
  },
  mounted: async function() {
    this.fetchOrg();
  },
};
</script>