export const userMenuItems = {
  dashboard: function(path) {
    return {
      title: "Dashboard",
      icon: "Dashboard",
      path,
      disabled: false,
    };
  },
  samples: function(path) {
    return {
      title: "Samples",
      icon: "Samples",
      path,
      disabled: false,
    };
  },
  execAdmin: {
    title: "Dashboard",
    icon: "Executive",
    path: { name: "Executive" },
    disabled: false,
  },
  executive: {
    title: "Executive",
    icon: "Executive",
    path: { name: "Executive" },
    disabled: false,
  },
  activities: {
    title: "Activities",
    icon: "Activities",
    path: { name: "Activities" },
    disabled: false,
  },
  projects: {
    title: "Projects",
    icon: "Projects",
    path: null,
    disabled: true,
  },
  sampleOffice: {
    title: "Samples",
    icon: "Samples",
    path: { name: "SampleOffice" },
    disabled: false,
  },
  members: {
    title: "Members",
    icon: "Members",
    path: { name: "Members" },
    disabled: false,
  },
  medOffice: {
    title: "Medical Centers",
    icon: "Medical",
    path: { name: "MedOffice" },
    disabled: false,
  },
};
