<template>
  <div
    class="flex items-center pl-4 text-smstext select-none cursor-pointer"
    @click="
      $route.path !== `/${station}/dash` &&
        $router.push(`/${station}/dash`, () => {})
    "
  >
    <NationBioIcon class="mt-2.5 mr-3.5" v-if="station === 'bio'" />
    <SeqLabIcon class="mr-3.5" v-if="station === 'lab'" />
    <GenomeBankIcon class="mr-3.5" v-if="station === 'bank'" />
    <div
      class="flex text-xl font-medium"
      :class="$route.path !== `/${station}/dash` && 'hover:text-primary'"
    >
      <p v-if="station === 'bio'">
        National<br />Bioresource Center
        <span
          :style="{ fontSize: '1.4rem' }"
          class="text-primary font-bold bg-blue-100 rounded-full"
          >②</span
        >
      </p>
      <p v-if="station === 'lab'">
        Sequencing Lab
        <span
          :style="{ fontSize: '1.4rem' }"
          class="text-lg text-primary font-bold bg-blue-100 rounded-full"
          >③</span
        >
      </p>
      <p v-if="station === 'bank'">
        Genome<br />Databank
        <span
          :style="{ fontSize: '1.4rem' }"
          class="text-lg text-primary font-bold bg-blue-100 rounded-full"
          >④</span
        >
      </p>
      <!-- <p
        class="rounded-full h-5 flex justify-center items-center border-smstext px-1.5 border mt-auto mb-1 ml-2 text-xs"
      >
        {{
          station === "bio"
            ? "2"
            : station === "lab"
            ? "3"
            : station === "bank" && "4"
        }}
      </p> -->
    </div>
  </div>
</template>

<script>
import GenomeBankIcon from "../../assets/svg/GenomeBankIcon.vue";
import NationBioIcon from "../../assets/svg/NationBioIcon.vue";
import SeqLabIcon from "../../assets/svg/SeqLabIcon.vue";
export default {
  components: { NationBioIcon, SeqLabIcon, GenomeBankIcon },
  props: ["station"],
  name: "NavStationItem",
};
</script>